import { Columbus } from '@clickbar/columbus-core'

function createColumbusProvider() {
  const mapboxToken = import.meta.env.VITE_MAPBOX_TOKEN

  if (typeof mapboxToken !== 'string') {
    throw new TypeError('MAPBOX_TOKEN is not defined')
  }

  const columbus = new Columbus(mapboxToken)

  columbus.addGlobalOptionsTransformer((options) => {
    const csrfToken = decodeURIComponent(
      document.cookie.match(/XSRF-TOKEN=([^;]+)/)?.at(1) ?? 'MISSING',
    )
    return {
      ...options,
      style: 'mapbox://styles/clickbar/ckzr6zkgr00pt14rwzuwrmg8p',
      zoom: 2,
      attributionControl: false,
      transformRequest: (origin, resource) => {
        if (resource !== 'Source') {
          return origin
        }

        const url = new URL(origin.url)

        if (window.location.hostname === url.hostname) {
          return {
            ...origin,
            headers: {
              'X-XSRF-TOKEN': csrfToken,
              'Content-Type': 'application/json',
              ...origin.headers,
            },
          }
        }

        return origin
      },
    }
  })

  return columbus
}

export { createColumbusProvider }
