import { ColumbusSymbol } from '@clickbar/columbus-vue'
import '@clickbar/tailwindui-vue/style.css'
import '@fontsource-variable/inter'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import { createInertiaApp, Link } from '@inertiajs/inertia-vue3'
import { InertiaProgress } from '@inertiajs/progress'
import { GesturePlugin } from '@vueuse/gesture'
import { MotionPlugin } from '@vueuse/motion'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { createApp, h } from 'vue'

import { createColumbusProvider } from './ColumbusProvider'
import sentryVuePlugin from './sentry'
import '../css/app.css'

const appName = window.document.querySelectorAll('title')[0].textContent ?? 'Laravel'

createInertiaApp({
  title: (title: string) => `${title} - ${appName}`,
  // @ts-expect-error don't know why this fails
  resolve: (name: string) =>
    resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
  setup({ el, app, props, plugin }) {
    createApp({ render: () => h(app, props) })
      .use(plugin)
      .use(autoAnimatePlugin)
      .use(GesturePlugin)
      .use(MotionPlugin)
      .use(sentryVuePlugin)
      .component('inertia-link', Link)
      .component('InertiaLink', Link)
      .provide(ColumbusSymbol, createColumbusProvider())
      .mixin({
        methods: { route },
      })
      .mount(el)
  },
})

InertiaProgress.init({ color: '#4B5563' })

window.mediaLibraryTranslations = {
  name: 'Name',
  fileTypeNotAllowed: 'Dieser Dateityp ist nicht erlaubt',
  tooLarge: 'Datei zu groß, max',
  tooSmall: 'Datei zu klein, min',
  tryAgain: 'bitte versuchen Sie es erneut',
  somethingWentWrong: 'Beim Hochladen ist ein Fehler aufgetreten',
  selectOrDrag: 'Datei(en) auswählen oder hier ablegen',
  selectOrDragMax: 'Maximal {maxItems} {file} auswählen oder hier ablegen',
  file: { singular: 'Datei', plural: 'Dateien' },
  anyImage: 'Bild',
  anyVideo: 'Video',
  goBack: 'Zurück',
  dropFile: 'Datei zum hochladen hier ablegen',
  dragHere: 'Datei hier ablegen',
  remove: 'Entfernen',
  download: 'Download',
}
